import React from 'react';
import './index.scss';
import { isMobile } from 'react-device-detect';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import { MOBXDefaultProps, RouterEnum } from '$types';
import w3aLogo from '$images/w3a.svg';
import { useRootStore } from '$hooks/useRootStore';
import helpIcon from '$images/teach-icon-1.svg';

function Header(props: MOBXDefaultProps) {
  const store = useRootStore();

  return (
    <header
      className="d-flex justify-content-center w-100"
      style={{
        backgroundColor: store.AppStore.headerColor,
        background: store.AppStore.headerColor,
        height: isMobile ? '87px' : '85px', // Высота 87px на мобилке
      }}
    >
      <div className="container d-flex align-items-center justify-content-between w-100 py-4 px-2">
        {/* Логотип */}
        <div
          className="cursor-pointer d-flex flex-row gap-2 align-items-center user-select-none"
          onClick={() => {
              window.location.href = 'https://edu.web3academy.pro';
          }}
          style={{
            marginLeft: !isMobile ? '112px' : undefined, // Отступ слева только на десктопе
          }}
        >
          <img
            src={w3aLogo}
            alt="Logo"
            style={{
              marginLeft: isMobile ? '7px' : null,
              width: isMobile ? '100px' : 'auto', // Логотип 49px на мобилке
              height: isMobile ? '49px' : 'auto', // Логотип 49px на мобилке
            }}
          />
        </div>

        {/* Блок с кнопкой */}
        <div className="d-flex gap-4 align-items-center">
          <a
            href="https://edu.web3academy.pro/teach/control"
            rel="noopener noreferrer"
            className="border border-white d-flex align-items-center gap-2 rounded-3 text-decoration-none text-white"
            style={{
              height: isMobile ? '49px' : 'auto', // Кнопка 49px на мобилке
              padding: '12px 16px',
              fontSize: '16px',
              borderRadius: '9px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={helpIcon} alt="Help" width={15} height={15} />
            <span className="fs-6">Перейти к обучению</span>
          </a>
        </div>
      </div>
    </header>
  );
}

export default MobXRouterDecorator(Header);
