import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import SvgIcons from '$common/SvgIcons';
import LinkLine from '$components/LinkLine';
import logo from '$images/logo.png';
import { RouterEnum } from '$types';
import "./index.scss"
import CountrySelect from "$components/CountrySelect";
import {useTranslation} from "react-i18next";

function Footer(props) {
  const {t} = useTranslation();
  return (
    <footer className="w-100 justify-content-center d-flex">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="d-flex flex-row gap-4 flex-wrap align-baseline p-4 justify-content-between w-100">
          <div className="d-flex flex-column justify-content-between gap-2" style={{ maxWidth: '265px' }}>
            <img className="align-self-start" src={logo} width={132} />
            <Typography variant="body2" color="white">
              {t('footer.caption')}
            </Typography>
            {/*<CountrySelect/>*/}
          </div>
          <div className="d-flex gap-2 flex-column justify-content-between gap-4">
            <div className="d-flex flex-row gap-2 justify-content-between">
              <a
                id="youtube_channel"
                href="https://t.me/web3academy_pro"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="youtube"
                  onClick={() => {
                    window.open('https://www.youtube.com/channel/UCmT4sCvAUUn77543vjh2Izg', '_blank');
                  }}
                />
              </a>
              <a
                id="discord_server"
                href="https://discord.gg/bWftgunvMM"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="discord"
                  onClick={() => {
                    window.open('https://discord.gg/bWftgunvMM', '_blank');
                  }}
                />
              </a>
              <a
                id="tg_channel"
                href="https://t.me/web3academy_pro"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="telegram"
                  onClick={() => {
                    window.open('https://t.me/web3academy_pro', '_blank');
                  }}
                />
              </a>

            </div>
            <a href="mailto:support@web3academy.pro">
              <Typography
                variant="link"
                color="white"
              >
                support@web3academy.pro
              </Typography>
            </a>
          </div>
          <div className="d-flex flex-column gap-2">
            {/*<Breadcrumbs aria-label="breadcrumb" separator="›">*/}
            {/*  <Link*/}
            {/*    underline="hover"*/}
            {/*    color="white"*/}
            {/*    href="https://docs.google.com/document/d/1mwGXDgaUg5GKaj8qMbNqI8RANhSwUxdHrZvfsQ2fPvM/edit"*/}
            {/*  >*/}
            {/*    <Typography variant="h6" color="white"> {t("footer.userPolicy")}</Typography>*/}
            {/*  </Link>*/}
            {/*</Breadcrumbs>*/}
            {/*<Breadcrumbs aria-label="breadcrumb" separator="›">*/}
            {/*  <Link*/}
            {/*    underline="hover"*/}
            {/*    color="white"*/}
            {/*    href={RouterEnum.TERMS_OF_SERVICE}*/}
            {/*  >*/}
            {/*    <Typography variant="h6" color="white">{t("footer.publicOffer")}</Typography>*/}
            {/*  </Link>*/}
            {/*</Breadcrumbs>*/}
            <Typography variant="h6" color="white">{t("footer.IP_NAME")}</Typography>
            <Typography variant="h6" color="white">{t("footer.IP_INN")}</Typography>
            <Typography variant="h6" color="white">{t("footer.IP_OGRN")}</Typography>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;
